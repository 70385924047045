import Header from "src/components/common/header/Header";
import styles from "./style.module.scss";
import { FormControl, TextField } from "@mui/material";
import Button from "src/components/common/button/Button";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { getSessionKey } from "src/helper/sessionServices";
import { useState, useEffect } from "react";

function AddAdvisor() {
  const { i18n, t } = useTranslation(["Advisor"]);
  const lang = getSessionKey("selectedLangCode");
  useEffect(() => {
    i18next.changeLanguage(lang);
  }, []);
  const navigate = useNavigate();
  const validationSchema = yup.object({
    email: yup
      .string()
      .email(`${t("advisor_validationEmail")}`)
      .required(`${t("advisor_validationEmailRequired")}`),
  });
  const [errorFormik, setErrorFormik] = useState("");
  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      //navigate("/acknowledgement");
      const payload = {
        email: values.email,
      };
      axios
        .post(
          "https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/verifyuseremail",
          payload,
          {
            headers: {
              Authorization: `Bearer ${getSessionKey("authToken").accesstoken}`,
            },
          }
        )
        .then((res) => {
          // console.log('res', res);
          if (res.data.success && Object.keys(res.data.data).length) {
            //navigate("/signup/details-form/ubo-details");
            /* setIsConfirmClicked(true);
          setIsDisabled(true);
          props.onConfirm();
          props.onDataFromChild(values.email); */
            const payload = {
              email: values.email,
              relationshipType: 1,
            };
            axios
              .post(
                "https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/relation",
                payload,
                {
                  headers: {
                    Authorization: `Bearer ${
                      getSessionKey("authToken").accesstoken
                    }`,
                  },
                }
              )
              .then((res) => {
                let historyData = {
                  reference: "relation added",
                };
                navigate("/acknowledgement", { state: historyData });
              })
              .catch((error) => {
                if (error.response.status === 403 || error.response.status === 401) {
                  navigate("/enter-pin");
                } else {
                  console.error("Error fetching data:", error.message);
                }
              });
          } else {
            /* setIsDisabled(false);
          setIsConfirmClicked(false); */
            formik.errors.email = res.data.message;
            setErrorFormik(res.data.message);
          }
        })
        .catch((error) => {
          formik.errors.email = error.message;
          setErrorFormik(error.message);
          if (error.response.status === 403 || error.response.status === 401) {
            navigate("/enter-pin");
          } else {
            console.error("Error fetching data:", error.message);
          }
        });
    },
  });

  return (
    <>
      <header>
        <Header backLink={true}></Header>
      </header>
      <main>
        <div className="lyt-main">
          <section>
            <div className="bs-section">
              <div className="sec-head">
                <h1 className={`cm-sec-title typ-big ${styles.title}`}>
                  {t("advisor_introTitle")}
                </h1>
                <p className={`${styles.subtitle}`}>
                  {t("advisor_pgSubTitle")}
                </p>
              </div>
              <div className="sec-cont">
                <div className="bs-form">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="form-group">
                      <FormControl variant="standard">
                        <TextField
                          id="email"
                          name="email"
                          label="Email ID"
                          placeholder="Email ID"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.email && Boolean(formik.errors.email)
                          }
                          helperText={
                            formik.touched.email && formik.errors.email
                          }
                        ></TextField>
                        {/* {(errorFormik !== "") && (
                        <span className="MuiFormHelperText-root Mui-error">
                          {`${errorFormik}`}
                        </span>
                      )} */}
                      </FormControl>
                    </div>
                    <div className="form-foot">
                      {/* <Button btnStyle="primary" type="submit" disabled>
                        {t("advisor_proceedBtn")}
                      </Button> */}
                      <Button
                        btnStyle="primary"
                        type="submit"
                        disabled={!formik.isValid}
                      >
                        {t("advisor_proceedBtn")}
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  );
}

export default AddAdvisor;
