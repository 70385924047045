import Header from "src/components/common/header/Header";
import styles from "./style.module.scss";
import BottomSheet from "src/components/bottom-sheet/BottomSheet";
import CheckBox from "src/components/common/checkbox/CheckBox";
import Button from "src/components/common/button/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { getSessionKey } from "src/helper/sessionServices";
import axios from "axios";

function ChatMessagePage() {
  const { i18n, t } = useTranslation(["Chat"]);
  const location = useLocation();
  const lang = getSessionKey("selectedLangCode");
  const navigate = useNavigate();
  const [openNoteDrawer, setOpenNoteDrawer] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [openRejectDrawer, setOpenRejectDrawer] = useState(false);
  const [clientData, setClientData] = useState<any>({});

  const handleCheckboxChange = (event: any) => {
    event.target.checked
      ? setIsCheckboxChecked(true)
      : setIsCheckboxChecked(false);
  };

  const handleButtonClick = (state: string) => {
    setOpenNoteDrawer(false);
    const headers = {
      headers: {
        Authorization: `Bearer ${
          getSessionKey("authToken") !== null
            ? getSessionKey("authToken").accesstoken
            : ""
        }`,
        "Content-Type": "application/json",
      },
    };
    const payload = {
      id: clientData?.id,
      companyid: clientData?.companyid,
      activerequestid: clientData?.activerequestid,
      activechatrequestid: clientData?.activechatrequestid,
      // location.state.reference === "notification"
      //   ? clientData?.activechatrequestid
      //   : clientData?.activerequestchathistory[0]?.activechatrequestid,
      activechatrequest: {
        request_status:
          state === "reject"
            ? "rejected"
            : state === "accept"
            ? "accepted"
            : "pending", //pending,accepted,rejected,closed
      },
    };
    axios
      .put(
        "https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/activechatrequesthistory",
        payload,
        headers
      )
      .then((res) => {
        if (res.data.success) {
          if (state === "accept") {
            let historyData = {
              notificationId: clientData?.id,
              activeChatReqId: clientData?.activechatrequestid,
              // location.state.reference === "notification"
              //   ? clientData?.activechatrequestid
              //   : clientData?.activerequestchathistory[0]
              //       ?.activechatrequestid,
              reference: location.state.reference,
            };
            navigate("/chat-details", { state: historyData });
          } else if (state === "reject" || state === "closed") {
            let historyData = {
              reference:
                state === "reject"
                  ? "reject chat"
                  : state === "closed"
                  ? "end chat"
                  : "",
            };
            navigate("/acknowledgement", { state: historyData });
          }
        }
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          navigate("/enter-pin");
        } else {
          console.error("Error fetching data:", error.message);
        }
      });
  };

  useEffect(() => {
    i18next.changeLanguage(lang);

    const userDataNotification =
      location.state.reference === "notification"
        ? getSessionKey("notificationData")
        : getSessionKey("activeChatRequestData");
    const filteredData = userDataNotification.filter((item: any) => {
      return item.id === location.state.notificationId;
    });

    setClientData(
      location.state.reference === "chatPage"
        ? filteredData[0]?.activerequestchathistory[0]
        : filteredData[0]
    );

    console.log(
      "filteredData[0]",
      location.state.reference === "chatPage"
        ? filteredData[0]?.activerequestchathistory[0]
        : filteredData[0]
    );
  }, []);
  return (
    <>
      <header>
        <Header backLink={true}></Header>
      </header>
      <main>
        <div className="lyt-main">
          <section>
            <div className="bs-section typ-news">
              <div className={`${styles.headWrap}`}>
                <h3 className={`${styles.title}`}>
                  {t("chat_messagePgTitle")}
                </h3>
                <p className={`${styles.subTitle}`}>
                  {t("chat_messagePgSubTitle")}
                </p>
              </div>
              <div className={`${styles.contWrap}`}>
                <div className={`${styles.card}`}>
                  <div className={`${styles.cardHead}`}>
                    <div className={`${styles.companyWrap}`}>
                      <span className={`${styles.companyLogo}`}>
                        <img
                          src={
                            location.state.reference === "chatPage"
                              ? clientData?.Company?.companylogo
                              : clientData?.company_details?.companylogo
                          }
                          alt=""
                        />
                      </span>
                      <div className={`${styles.companyIntro}`}>
                        <strong className={`${styles.companyName}`}>
                          {location.state.reference === "chatPage"
                            ? clientData?.Company?.name
                            : clientData?.company_details?.name}
                        </strong>
                        <span className={`${styles.tag}`}>
                          {location.state.reference === "chatPage"
                            ? clientData?.Company?.country
                            : clientData?.company_details?.country}
                        </span>
                      </div>
                    </div>
                    {clientData?.is_read === null ||
                    clientData?.is_read === false ? (
                      <span className={`${styles.cardTag}`}>
                        {t("chat_newTagTitle")}
                      </span>
                    ) : null}
                  </div>
                  <div className={`${styles.cardFoot}`}>
                    <p className={`${styles.desc}`}>{clientData?.message}</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className={`${styles.footWrap}`}>
            <Button
              btnStyle="secondary"
              onClick={() => {
                setOpenRejectDrawer(true);
              }}
            >
              {t("chat_rejectBtn")}
            </Button>
            <Button
              btnStyle="primary"
              onClick={() => {
                setOpenNoteDrawer(true);
              }}
            >
              {t("chat_chatBtn")}
            </Button>
          </div>
        </div>
      </main>
      <BottomSheet
        title={t("chat_drawerNoteTitle")}
        primaryBtnText={t("chat_drawerAgreeBtn")}
        size="medium"
        open={openNoteDrawer}
        setOpen={setOpenNoteDrawer}
        btnDisabled={!isCheckboxChecked}
        btnOnClick={() => {
          handleButtonClick("accept");
        }}
      >
        <div className={`${styles.contentWrap}`}>
          <p className={`${styles.desc}`}>{t("chat_drawerNoteMessage")}</p>
        </div>
        <div className="check-foot">
          <CheckBox
            id="legalNote"
            label={t("chat_drawerNoteAcceptCheck")}
            name="Legal Note"
            onChange={handleCheckboxChange}
            isChecked={isCheckboxChecked}
          ></CheckBox>
        </div>
      </BottomSheet>
      <BottomSheet
        title={t("chat_messageDrawerTitle")}
        primaryBtnText={t("chat_rejectBtn")}
        size="large"
        center={true}
        open={openRejectDrawer}
        setOpen={setOpenRejectDrawer}
        btnOnClick={() => {
          handleButtonClick("reject");
        }}
      >
        <div className={`${styles.rejectDrawer}`}>
          <div className={`${styles.rejectWrap}`}>
            <span className={`${styles.remove}`}></span>
            <p className={`${styles.content}`}>
              {t("chat_messageDrawerSubTitle")}
            </p>
          </div>
        </div>
      </BottomSheet>
    </>
  );
}

export default ChatMessagePage;
