import { useEffect, useState } from "react";
import styles from "./style.module.scss";
import CompanyChatCard from "src/components/company-chat-card/CompanyChatCard";
import React from "react";
import { Tab, Tabs } from "@mui/material";
import RequestCard from "src/components/request-card/RequestCard";
import Exploration from "src/components/exploration/exploration";
import { getSessionKey, setSessionKey } from "src/helper/sessionServices";
import Loader from "src/components/common/loader/Loader";
import { useLoader } from "src/context/LoaderContext";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import axios from "axios";
import SuccessError from "src/components/success-error/SuccessError";
import { useNavigate } from "react-router-dom";
import SelectClient from "src/components/select-client/SelectClient";
import BottomSheet from "src/components/bottom-sheet/BottomSheet";

function ChatPage() {
  const { i18n, t } = useTranslation(["Chat"]);
  const lang = getSessionKey("selectedLangCode");
  const { loading, setLoading } = useLoader();
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  const [outgoingMsg, setOutgoingMsg] = React.useState<any[]>([]);
  const [finalOutgoingMsg, setFinalOutgoingMsg] = React.useState<any[]>([]);
  const [incomingMsg, setIncomingMsg] = React.useState<any[]>([]);
  const [finalIncomingMsg, setFinalIncomingMsg] = React.useState<any[]>([]);
  const [pendingMsg, setPendingMsg] = React.useState<any[]>([]);
  const [activeChatReqData, setActiveChatReqData] = React.useState<any[]>([]);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const [relationData, setRelationData] = useState<any>([]);
  const [settingData, setSettingData] = useState<any>([]);
  const [clientList, setClientList] = useState<any>([]);
  const [selectedClient, setSelectedClient] = useState<any>({});
  const [clientSearchData, setClientSearchData] = useState<any[]>([]);
  const [bsBtnState, setBSbtnState] = useState(true);
  const [openClientDrawer, setOpenClientDrawer] = useState(false);

  useEffect(() => {
    i18next.changeLanguage(lang);
  }, []);

  useEffect(() => {
    if (getSessionKey("userData")?.access_group === "advanced" || getSessionKey("userData")?.access_group === "intermediate") {
      axios
        .get(
          "https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/activechatrequest",
          {
            headers: {
              Authorization: `Bearer ${
                getSessionKey("authToken") !== null
                  ? getSessionKey("authToken").accesstoken
                  : ""
              }`,
            },
          }
        )
        .then((response) => {
          let tempOutgoingArr: any[] = [];
          let tempIncomingArr: any[] = [];
          let tempPendingArr: any[] = [];
          response?.data?.data.map((item: any) => {
            if (item?.sender_id === getSessionKey("authToken").id) {
              tempOutgoingArr.push(item);
            } else if (
              item?.receiver_id === getSessionKey("authToken").id &&
              item?.request_status !== "pending"
            ) {
              tempIncomingArr.push(item);
            } else if (
              item?.receiver_id === getSessionKey("authToken").id &&
              item?.request_status === "pending"
            ) {
              tempPendingArr.push(item);
            }
          });
          setOutgoingMsg(tempOutgoingArr);
          setIncomingMsg(tempIncomingArr);
          setPendingMsg(tempPendingArr);
          setActiveChatReqData(response?.data?.data);
          setSessionKey("activeChatRequestData", response?.data?.data);
        })
        .catch((error) => {
          if (error.response.status === 403 || error.response.status === 401) {
            navigate("/enter-pin");
          } else {
            console.error("Error fetching data:", error.message);
          }
        });
    }

    if (getSessionKey("userRole") === "advisor") {
      setLoading(true);
      axios
        .get("https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/relation", {
          headers: {
            Authorization: `Bearer ${
              getSessionKey("authToken") !== null
                ? getSessionKey("authToken").accesstoken
                : ""
            }`,
          },
        })
        .then((response) => {
          setRelationData(response?.data?.data);
        })
        .catch((error) => {
          if (error.response.status === 403 || error.response.status === 401) {
            navigate("/enter-pin");
          } else {
            console.error("Error fetching data:", error.message);
          }
        });

      axios
        .get("https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/setting", {
          headers: {
            Authorization: `Bearer ${
              getSessionKey("authToken") !== null
                ? getSessionKey("authToken").accesstoken
                : ""
            }`,
          },
        })
        .then((response) => {
          setSettingData(response?.data?.data);
        })
        .catch((error) => {
          if (error.response.status === 403 || error.response.status === 401) {
            navigate("/enter-pin");
          } else {
            console.error("Error fetching data:", error.message);
          }
        });
    }
  }, []);

  useEffect(() => {
    // console.log("incomingMsg", incomingMsg);
    if (incomingMsg.length > 0) {
      let tempArr: any[] = [];
      incomingMsg.forEach((item: any, index: number) => {
        // console.log("item?.Sender_Detail?.role", item);
        if (
          tempArr.some((ele) => ele.companyid === item.companyid) &&
          item?.request_status === "accepted"
        ) {
          let idx = tempArr.findIndex(
            (ele) => ele.companyid === item.companyid
          );
          let tempObj =
            item.activerequestchathistory[
              item.activerequestchathistory.length - 1
            ];
          tempObj.firstname =
            item?.activerequestchathistory[0]?.Sender_Detail?.role ===
            "legalrepresent"
              ? item?.activerequestchathistory[0]?.Sender_Detail?.lrDetails[0]
                  ?.companyname
              : item?.activerequestchathistory[0]?.Sender_Detail?.userdetail
                  ?.firstname;
          tempArr[idx]?.activerequestchathistory.push(tempObj);
        } else if (item?.request_status === "accepted") {
          let obj: any = {};
          let tempHistoryArr = [];
          obj.companyid = item.companyid;
          let tempObj =
            item.activerequestchathistory[
              item.activerequestchathistory.length - 1
            ];
          tempObj.firstname =
            item?.activerequestchathistory[0]?.Sender_Detail?.role ===
            "legalrepresent"
              ? item?.activerequestchathistory[0]?.Sender_Detail?.lrDetails[0]
                  ?.companyname
              : item?.activerequestchathistory[0]?.Sender_Detail?.userdetail
                  ?.firstname;
          tempHistoryArr.push(tempObj);
          obj.Company = item.Company;
          obj.activerequestid = item.activerequestid;
          obj.ai_officer_status = item.ai_officer_status;
          obj.ai_officer_request_raised_by = item.ai_officer_request_raised_by;
          obj.id = item.id;
          obj.sender_id = item.sender_id;
          obj.receiver_id = item.receiver_id;
          obj.activerequestchathistory = tempHistoryArr;
          tempArr.push(obj);
        }
      });
      setFinalIncomingMsg(tempArr);
    }
  }, [incomingMsg]);

  useEffect(() => {
    if (outgoingMsg.length > 0) {
      let tempArr: any[] = [];
      outgoingMsg.forEach((item: any, index: number) => {
        if (
          tempArr.some((ele) => ele.companyid === item.companyid)
          // && item?.request_status === "accepted"
        ) {
          let idx = tempArr.findIndex(
            (ele) => ele.companyid === item.companyid
          );
          let tempObj =
            item.activerequestchathistory[
              item.activerequestchathistory.length - 1
            ];
          tempObj.firstname =
            item?.activerequestchathistory[0]?.Receiver_Detail?.role ===
            "legalrepresent"
              ? item?.activerequestchathistory[0]?.Receiver_Detail?.lrDetails[0]
                  ?.companyname
              : item?.activerequestchathistory[0]?.Receiver_Detail?.userdetail
                  ?.firstname;
          tempArr[idx]?.activerequestchathistory.push(tempObj);
        } else {
        // if (item?.request_status === "accepted") {
          let obj: any = {};
          let tempHistoryArr = [];
          obj.companyid = item.companyid;
          let tempObj =
            item.activerequestchathistory[
              item.activerequestchathistory.length - 1
            ];
          tempObj.firstname =
            item?.activerequestchathistory[0]?.Receiver_Detail?.role ===
            "legalrepresent"
              ? item?.activerequestchathistory[0]?.Receiver_Detail?.lrDetails[0]
                  ?.companyname
              : item?.activerequestchathistory[0]?.Receiver_Detail?.userdetail
                  ?.firstname;
          tempHistoryArr.push(tempObj);
          obj.Company = item.Company;
          obj.activerequestid = item.activerequestid;
          obj.ai_officer_status = item.ai_officer_status;
          obj.ai_officer_request_raised_by = item.ai_officer_request_raised_by;
          obj.id = item.id;
          obj.createdAt = item.createdAt;
          obj.sender_id = item.sender_id;
          obj.receiver_id = item.receiver_id;
          obj.activerequestchathistory = tempHistoryArr;
          obj.firstname =
            item?.activerequestchathistory[0]?.Receiver_Detail?.userdetail?.firstname;
          obj.request_status = item?.request_status;
          tempArr.push(obj);
        }
      });
      setFinalOutgoingMsg(tempArr);
    }
  }, [outgoingMsg]);

  const handleTabChange = (param: any) => {
    setValue(param);
  };

  useEffect(() => {
    if (relationData.length > 0 && settingData.length > 0) {
      let temp: any = JSON.parse(JSON.stringify(relationData));
      temp.map((item: any) => {
        settingData.forEach((element: any) => {
          if (item?.investor?.id === element?.investorId) {
            item["viewChat"] = element?.viewChat;
            item["participateinChat"] = element?.participateinChat;
            item["expressInterest"] = element?.expressInterest;
            item["viewPortfolio"] = element?.viewPortfolio;
          }
        });
      });
      setLoading(false);
      setClientList(temp);
      setClientSearchData(temp);
      setSessionKey("relationData", temp);
      // console.log('temp', temp)

      if (getSessionKey("selectedClient") !== null) {
        const filteredClient = temp.filter((item: any) => {
          return item.id === getSessionKey("selectedClient")?.id;
        });
        setSelectedClient(filteredClient[0]);
        handleProceedToGetActiveChatRequest(filteredClient[0]);
      }
    } else {
      setLoading(false);
    }
  }, [relationData, settingData]);

  const [searchTerm, setSearchTerm] = useState("");
  useEffect(() => {
    if (searchTerm.length >= 3) {
      let tempClientArr = [];
      tempClientArr = clientSearchData.filter((item) => {
        return item?.investor?.role === "legalrepresent"
          ? item?.investor?.userLrdetail[0]?.companyname
              .toLowerCase()
              .includes(searchTerm.toLowerCase())
          : item?.investor?.userdetail?.firstname
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
              item?.investor?.userdetail?.lastname
                .toLowerCase()
                .includes(searchTerm.toLowerCase());
      });
      setClientSearchData(tempClientArr);
    } else {
      setClientSearchData(clientList);
    }
  }, [searchTerm]);

  const handleRadio = (e: any, item: any) => {
    setSelectedClient(item);
    setBSbtnState(false);
  };

  const handleSelectClient = () => {
    setOpenClientDrawer(true);
  };

  const handleProceedToGetActiveChatRequest = (item: any) => {
    setSessionKey("selectedClient", item);
    setOpenClientDrawer(false);
    setLoading(true);
    axios
      .get(
        `https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/activechatrequest?investorid=${item?.investor?.id}`,
        {
          headers: {
            Authorization: `Bearer ${
              getSessionKey("authToken") !== null
                ? getSessionKey("authToken").accesstoken
                : ""
            }`,
          },
        }
      )
      .then((response) => {
        let tempOutgoingArr: any[] = [];
        let tempIncomingArr: any[] = [];
        let tempPendingArr: any[] = [];
        response?.data?.data.map((ele: any) => {
          if (ele?.sender_id === item?.investor?.id) {
            tempOutgoingArr.push(ele);
          } else if (
            ele?.receiver_id === item?.investor?.id &&
            ele?.request_status !== "pending"
          ) {
            tempIncomingArr.push(ele);
          } else if (
            ele?.receiver_id === item?.investor?.id &&
            ele?.request_status === "pending"
          ) {
            tempPendingArr.push(ele);
          }
        });
        setOutgoingMsg(tempOutgoingArr);
        setIncomingMsg(tempIncomingArr);
        setPendingMsg(tempPendingArr);
        setActiveChatReqData(response?.data?.data);
        setSessionKey("activeChatRequestData", response?.data?.data);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          navigate("/enter-pin");
        } else {
          console.error("Error fetching data:", error.message);
        }
        setLoading(false);
      });
  };

  console.log('activeChatReqData', activeChatReqData);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {getSessionKey("userRole") === "advisor" &&
          getSessionKey("selectedClient") !== null &&
          !getSessionKey("selectedClient")?.viewChat ? (
            <>
              <div className="blank-wrap">
                <SuccessError
                  title={t("portfoliodetails_unauthorizedAccessTitle")}
                  content={t("portfoliodetails_unauthorizedAccessSubTitle")}
                  type="account-error"
                  showBtn={true}
                  handleSelectClient={handleSelectClient}
                />
              </div>
            </>
          ) : getSessionKey("userRole") === "explorer" &&
            getSessionKey("authToken") === null ? (
            <div className="blank-wrap">
              <Exploration
                buttonText={t("portfoliodetails_registerTitle")}
                navigationLink="/login"
                logoURL="/assets/logos/explore-bids.png"
                description={t("portfoliodetails_registerSubTitle")}
              />
            </div>
          ) : getSessionKey("userData")?.status === "freeze" &&
            getSessionKey("authToken") !== null ? (
            <div className="blank-wrap">
              <SuccessError
                title={t("portfoliodetails_frozenAccountTitle")}
                content={t("portfoliodetails_frozenAccountSubTitle")}
                type="account-error"
              />
            </div>
          ) : getSessionKey("userData").Portfolios.length <= 0 &&
            getSessionKey("userData")?.access_group === "intermediate" &&
            getSessionKey("userRole") !== "advisor" &&
            getSessionKey("authToken") !== null &&
            activeChatReqData.length <= 0 ? (
            <div className="blank-wrap">
              <Exploration
                buttonText={t("chat_exploreViewBtn")}
                navigationLink="/opportunities"
                logoURL="/assets/logos/explore-bids.png"
                description={t("chat_exploreTitle")}
              />
            </div>
          ) : getSessionKey("userRole") === "advisor" &&
            getSessionKey("selectedClient") === null ? (
            <div className="blank-wrap">
              <SelectClient handleSelectClient={handleSelectClient} />
            </div>
          ) : (
            <>
              <div className="lyt-main typ-main">
                <section>
                  <div className="bs-section typ-depth">
                    <div className="sec-cont">
                      <div className="bs-tabs typ-btn">
                        <Tabs value={value} onChange={handleChange}>
                          <Tab label={t("chat_outgoingTab")} />
                          <Tab label={t("chat_incomingTab")} />
                          <Tab label={t("chat_pendingTab")} />
                        </Tabs>
                        <div className={`${styles.panelWrap}`}>
                          <div
                            className={`${styles.tabPanel}`}
                            role="tabpanel"
                            hidden={value !== 0}
                          >
                            {finalOutgoingMsg.length > 0 ? (
                              <div className={`${styles.bidsList}`}>
                                {finalOutgoingMsg.map((item, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className={`${styles.item}`}
                                    >
                                      <CompanyChatCard
                                        {...item}
                                        cardType="outgoing"
                                      ></CompanyChatCard>
                                    </div>
                                  );
                                })}
                              </div>
                            ) : (
                              <Exploration
                                buttonText={t("chat_exploreViewBtn")}
                                data={2}
                                handleBtnClick={handleTabChange}
                                logoURL="/assets/logos/note-warning.png"
                                description={t("chat_outgoingEmptyMessage")}
                              />
                            )}
                          </div>
                          <div
                            className={`${styles.tabPanel}`}
                            role="tabpanel"
                            hidden={value !== 1}
                          >
                            {finalIncomingMsg.length > 0 ? (
                              <div className={`${styles.bidsList}`}>
                                {finalIncomingMsg.map((item, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className={`${styles.item}`}
                                    >
                                      <CompanyChatCard
                                        {...item}
                                        cardType="incoming"
                                      ></CompanyChatCard>
                                    </div>
                                  );
                                })}
                              </div>
                            ) : (
                              <Exploration
                                buttonText={t("chat_exploreViewBtn")}
                                data={2}
                                handleBtnClick={handleTabChange}
                                logoURL="/assets/logos/note-warning.png"
                                description={t("chat_incomingEmptyMessage")}
                              />
                            )}
                          </div>
                          <div
                            className={`${styles.tabPanel}`}
                            role="tabpanel"
                            hidden={value !== 2}
                          >
                            {pendingMsg.length > 0 ? (
                              <div className={`${styles.bidsList}`}>
                                {pendingMsg.map((item, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className={`${styles.item}`}
                                    >
                                      <RequestCard
                                        {...item}
                                        parentRef="chatPage"
                                        index={index}
                                      ></RequestCard>
                                      {/* <CompanyChatCard
                                        {...item}
                                      ></CompanyChatCard> */}
                                    </div>
                                  );
                                })}
                              </div>
                            ) : (
                              <Exploration
                                buttonText={t("chat_exploreViewBtn")}
                                navigationLink="/impact"
                                logoURL="/assets/logos/note-warning.png"
                                description={t("chat_pendingEmptyMessage")}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </>
          )}
          <BottomSheet
            open={openClientDrawer}
            primaryBtnText={t("portfoliodetails_drawerProceedBtn")}
            title={t("portfoliodetails_drawerSelectTitle")}
            subText={t("portfoliodetails_drawerSelectSubTitle")}
            size="large"
            setOpen={setOpenClientDrawer}
            btnOnClick={() => {
              handleProceedToGetActiveChatRequest(selectedClient);
            }}
            btnDisabled={bsBtnState}
          >
            <div className="bs-form">
              <div className={`${styles.clientDrawer}`}>
                {/* <form action=""> */}
                <div className="search-wrap">
                  <span className="search-icon"></span>
                  <input
                    type="text"
                    placeholder={t("portfoliodetails_searchPlaceholder")}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <button
                    className="close-btn"
                    type="button"
                    onClick={() => setSearchTerm("")}
                  >
                    <i></i>
                  </button>
                </div>
                <div className={`${styles.selectionWrap}`}>
                  <ul className={`${styles.clientList}`}>
                    {clientSearchData.map((item: any, index: number) => {
                      return (
                        <li key={index} className={`${styles.clientItem}`}>
                          <input
                            onChange={(e) => handleRadio(e, item)}
                            type="radio"
                            name="client-list"
                            id={item.id}
                            checked={
                              item.id === selectedClient.id &&
                              item.viewPortfolio === true
                            }
                            disabled={!item.viewPortfolio ? true : false}
                          />
                          <label htmlFor={item.id}>
                            <span className={`${styles.client}`}>
                              {item?.investor?.role === "legalrepresent"
                                ? `${item?.investor?.userLrdetail[0]?.companyname}`
                                : `${
                                    item?.investor?.userdetail?.firstname
                                  }${" "}${
                                    item?.investor?.userdetail?.lastname
                                  }`}
                            </span>
                            <span className={`${styles.clientCode}`}></span>
                          </label>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                {/* </form> */}
              </div>
            </div>
          </BottomSheet>
        </>
      )}
    </>
  );
}

export default ChatPage;
