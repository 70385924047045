import OtpInput from "react-otp-input";
import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import Button from "src/components/common/button/Button";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "src/components/common/header/Header";
import axios from "axios";
import { getSessionKey } from "src/helper/sessionServices";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { setSessionKey } from "src/helper/sessionServices";
import { useLoader } from "src/context/LoaderContext";
import Loader from "src/components/common/loader/Loader";
export const StepContext = React.createContext<{
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
} | null>(null);

function ExistingPin() {
  const { loading, setLoading } = useLoader();
  const { i18n, t } = useTranslation(["ProfileSettings"]);
  const navigate = useNavigate();
  const [btnValid, setBtnValid] = useState(false);
  const [showError, setShowError] = useState(false);
  const [otp, setOtp] = useState("");
  useEffect(() => {
    setBtnValid(otp.length === 4);
  }, [otp]);

  const handleClick = () => {
    setShowError(false);
    setLoading(true);
    //navigate("/profile-update/add-profile-picture");
    const userData = getSessionKey("userData");
    const payload = {
      pin: Number(otp),
      id: userData.id, // current user id
    };
    axios
      .post("https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/pin", payload, {
        headers: {
          Authorization: `Bearer ${getSessionKey("authToken").accesstoken}`,
        },
      })
      .then((res) => {
        console.log("res", res);
        if (res.data.success) {
          setLoading(false);
          navigate("/create-pin");
        } else {
          setLoading(false);
          setShowError(true);
        }
      })
      .catch((error) => {
        setLoading(false);
        setShowError(true);
        if (error.response.status === 403 || error.response.status === 401) {
          navigate("/enter-pin");
        } else {
          console.error("Error fetching data:", error.message);
        }
      });
  };

  const [step, setStep] = useState(1);
  return (
    <>
      {loading ? <Loader /> : <></>}
      <header>
        <Header backLink={true}></Header>
      </header>

      <div className="lyt-main">
        <div className={`${styles.section}`}>
          <div className={`${styles.sectionHead}`}>
            <div className={`${styles.titleWrap}`}>
              <h1 className={`${styles.pgTitle}`}>
                {t("profilesettings_existingPinTitle")}
              </h1>
            </div>
            <p className={`${styles.subTitle}`}>
              {t("profilesettings_existingPinDesc")}
            </p>
          </div>
          <div className="bs-form typ-pin">
            <form>
              <div className="form-body">
                <div className="form-group">
                  <div className={`${showError ? "invalid" : ""} otp-wrap`}>
                    {/* <label htmlFor="otp">
                      {t("profilesettings_createNewPin")}
                    </label> */}
                    <OtpInput
                      // id="otpPin"
                      // name="otpPin"
                      value={otp}
                      onChange={setOtp}
                      numInputs={4}
                      inputType="number"
                      renderInput={(props) => <input {...props} required />}
                    />
                    {showError && (
                      <span className="MuiFormHelperText-root Mui-error">
                        {t("profilesettings_incorrectPin")}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-foot">
                <Button
                  btnStyle="primary"
                  type="button"
                  onClick={handleClick}
                  disabled={!btnValid}
                >
                  {t("profilesettings_confirmBtn")}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ExistingPin;
