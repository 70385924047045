import Header from "src/components/common/header/Header";
import Button from "../../components/common/button/Button";
import styles from "./style.module.scss";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import axios from "axios";
import { getSessionKey, setSessionKey } from "src/helper/sessionServices";
import Loader from "src/components/common/loader/Loader";
import { useLoader } from "src/context/LoaderContext";

function AdvisorClientRelation() {
  const { loading, setLoading } = useLoader();
  const { i18n, t } = useTranslation(["Advisor"]);
  const navigate = useNavigate();
  const [relationData, setRelationData] = useState<any>([]);

  useEffect(() => {
    setLoading(true);
    axios
      .get("https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/relation", {
        headers: {
          Authorization: `Bearer ${
            getSessionKey("authToken") !== null
              ? getSessionKey("authToken").accesstoken
              : ""
          }`,
        },
      })
      .then((response) => {
        setRelationData(response?.data?.data);
        setSessionKey("relationData", response?.data?.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 401) {
          navigate("/enter-pin");
        } else {
          console.error("Error fetching data:", error.message);
        }
      });
  }, []);

  const handleChatDetailsClick = (item: any) => {
    let historyData = {
      selectedUser: item,
      reference: "socketListPage",
    };
    navigate(`/chat-details-socket`, { state: historyData });
  };

  return (
    <>
      {loading ? <Loader /> : <></>}
      <header>
        <Header backLink={true}></Header>
      </header>
      <main>
        <div className="lyt-main typ-btn-spacing">
          <section>
            <div className="bs-section typ-lg-top">
              <div className="sec-head">
                <h1 className={`cm-sec-title typ-big ${styles.title}`}>
                  {/* {t("advisor_detailsTitle")} */}{" "}
                  {`${
                    getSessionKey("userRole") === "advisor"
                      ? `${t("advisor_chatClientTitle")}`
                      : `${t("advisor_chatAdvisorTitle")}`
                  }`}
                </h1>
                <p className={`${styles.subTitle}`}>
                  {/* {t("advisor_clientSubTitle")} */}{" "}
                  {`${
                    getSessionKey("userRole") === "advisor"
                      ? `${t("advisor_chatClientSubTitle")}`
                      : `${t("advisor_chatAdvisorSubTitle")}`
                  }`}
                </p>
              </div>
              <div className="sec-cont">
                <div className={`${styles.subWrap}`}>
                  <ul className={`${styles.subList}`}>
                    {relationData.length > 0 ? (
                      relationData.map((item: any, index: number) => {
                        return item?.requestStatus === "approved" ? (
                          <li
                            className={`${styles.subItem}`}
                            onClick={() => {
                              handleChatDetailsClick(item);
                            }}
                            key={index}
                          >
                            {/* <Link to={`/chat-details-socket?id=${item?.id}`}> */}
                            <div className={`${styles.logoWrap}`}>
                              <img
                                src={
                                  getSessionKey("userRole") === "advisor"
                                    ? item?.investor?.userdetail?.img
                                    : item?.advisor?.userdetail.img
                                }
                                alt="ferruccio"
                              />
                            </div>
                            <div className={`${styles.titleWrap}`}>
                              <strong className={`${styles.clientName}`}>
                                {getSessionKey("userRole") === "advisor" ? (
                                  <>
                                    {item?.investor?.role === "legalrepresent"
                                      ? `${item?.investor?.userLrdetail[0]?.companyname}`
                                      : `${
                                          item?.investor?.userdetail?.firstname
                                        }${" "}${
                                          item?.investor?.userdetail?.lastname
                                        }`}
                                  </>
                                ) : (
                                  <>
                                    {item?.advisor?.userdetail.firstname}{" "}
                                    {item?.advisor?.userdetail.lastname}
                                  </>
                                )}
                              </strong>
                            </div>
                            <span className={`${styles.arrowIcon}`}></span>
                            {/* </Link> */}
                          </li>
                        ) : (
                          <></>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </section>
        </div>
        {/*  <div className={`${styles.footWrap}`}>
          <Button btnStyle="primary" onClick={handleClick}>
            {t("advisor_introTitle")}
          </Button>
        </div> */}
      </main>
    </>
  );
}

export default AdvisorClientRelation;
