import { useEffect, useMemo, useState } from "react";
import BottomSheet from "../bottom-sheet/BottomSheet";
import styles from "./style.module.scss";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

import axios from "axios";
import { getSessionKey, setSessionKey } from "src/helper/sessionServices";
import { useNavigate } from "react-router-dom";
export interface ProfileData {
  id?: string;
  firstName?: string;
  lastName?: string;
  profileImage?: string;
}

function ProfileCard(props: ProfileData) {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation(["ProfileSettings"]);
  const [openUploadDrawer, setOpenUploadDrawer] = useState(false);
  const [profileImageURL, setProfileImageURL] = useState("");
  const [tempProfileImage, setTempProfileImage] = useState("");
  const [openPreviewDrawer, setOpenPreviewDrawer] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [previewFileObj, setPreviewFileObj] = useState<Blob>({} as Blob);
  const [isDisabledUpload, setIsDisabledUpload] = useState(true);
  // const [previewURL, setPreviewURL] = useState("");
  //useEffect(()=>{
  //setProfileImageURL(props.profileImage!);
  //},[])
  const [uploadedFile, setUploadedFile] = useState({
    id: "",
    name: "",
    size: "",
    type: "",
  });
  // const [previewFileObj, setPreviewFileObj] = useState<Blob>({} as Blob);
  const nameInitials = (fname: string, lname: string): string => {
    setProfileImageURL(props.profileImage!);
    return fname.charAt(0) + lname.charAt(0);
  };

  useEffect(() => {
    if (
      props?.profileImage !== "" &&
      props?.profileImage !== null &&
      props?.profileImage !== undefined
    )
      setProfileImageURL(props?.profileImage);
  }, []);

  const handleUpload = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      /* setUploadedFile({
        id: "" + file.lastModified,
        name: file.name,
        size: "" + Math.round(file.size / 1024) + " KB",
        type: file.type,
      }); */

      const fileReader = new FileReader();
      fileReader.onload = (event) => {
        setTempProfileImage(event.target?.result as string);
      };
      fileReader.readAsDataURL(file);

      if (file.size > 5 * 1024 * 1024) {
        setErrorMessage(`${t("profilesettings_errorFileSize")}`);
        return;
      }
      // Check file type
      if (!file.type.includes("image") && !file.type.includes("pdf")) {
        setErrorMessage(`${t("profilesettings_errorUploadFile")}`);
        return;
      }

      const formData = new FormData();
      formData.append("file", file);
      formData.append(
        "type",
        file.type.includes("pdf") ? "document" : "images"
      );
      axios
        .post(
          "https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/documentUpload",
          formData,
          {
            headers: {
              Authorization: `Bearer ${getSessionKey("authToken").accesstoken}`,
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          if (res.data.success) {
            setErrorMessage(null);
            setUploadedFile({
              id: "" + e.target.files[0].lastModified,
              name: e.target.files[0].name,
              size: "" + Math.round(e.target.files[0].size / 1024),
              type: e.target.files[0].type,
            });
            setPreviewFileObj(e.target.files[0]);
            setSessionKey("profilePicData", res.data);
            setIsDisabledUpload(false);
            // harcoded pdf file url remove after storage logic integarted
            // res.data.data.fileurl = e.target.files[0].type === "application/pdf" ? "https://s3.amazonaws.com/awsdocs/S3/latest/s3-gsg.pdf" : "https://ucarecdn.com/b223131b-a0a0-4812-9805-0029fe27c169/banyan_logo.png";
            //if (props.getFileData) props.getFileData(e.target.files[0], res.data.data, props?.index);
          }
        })
        .catch((error) => {
          if (error.response.status === 403 || error.response.status === 401) {
            navigate("/enter-pin");
          } else {
            console.error("Error fetching data:", error.message);
          }
        });
    }
  };

  const handleConfirmUpload = () => {
    setProfileImageURL(tempProfileImage); // Set the actual profile picture
    //setUploadedFile({ id: "", name: "", size: "", type: "" });
    const profileImgData = getSessionKey("profilePicData");
    //props.profileImage = profileImgData.fileurl;
    const userData = getSessionKey("userData");
    if (userData !== "") {
      const payload = {
        id: userData?.id,
        userdetail: {
          img: profileImgData.data.fileurl,
        },
      };
      axios
        .put(
          "https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/user",
          payload,
          {
            headers: {
              Authorization: `Bearer ${getSessionKey("authToken").accesstoken}`,
            },
          }
        )
        .then((res) => {
          // console.log('res', res);
          if (res.data.success) {
            userData.userdetail.img = profileImgData.data.fileurl;
            setSessionKey("userData", userData);
            setOpenUploadDrawer(false); // Close the upload drawer
            setOpenPreviewDrawer(false); // Close the preview drawer
            setProfileImageURL(profileImgData.data.fileurl);
            //navigate("/client-approve");
          } else {
          }
        })
        .catch((error) => {
          if (error.response.status === 403 || error.response.status === 401) {
            navigate("/enter-pin");
          } else {
            console.error("Error fetching data:", error.message);
          }
        });
    }
  };

  const handleCancelPreview = () => {
    setOpenPreviewDrawer(false);
    setUploadedFile({ id: "", name: "", size: "", type: "" });
    setTempProfileImage("");
  };

  const handleCloseDrawer = () => {
    setOpenUploadDrawer(false);
  };
  return (
    <>
      <div className={`${styles.cardWrap}`}>
        <strong>
          {props.firstName} {props.lastName}
        </strong>
        <div className={`${styles.logoWrap}`}>
          <button
            type="button"
            className={
              props.profileImage !== "" || profileImageURL !== ""
                ? styles.uploaded
                : ""
            }
            onClick={() => setOpenUploadDrawer(true)}
          >
            {props.profileImage !== "" || profileImageURL !== "" ? (
              <img
                src={
                  profileImageURL !== undefined
                    ? profileImageURL
                    : props?.profileImage
                }
                alt={`${props.firstName} ${props.lastName}`}
              />
            ) : (
              <>
                <span>{nameInitials(props.firstName!, props.lastName!)}</span>
                <i></i>
              </>
            )}
          </button>
        </div>
      </div>
      <BottomSheet
        size="medium"
        open={openUploadDrawer}
        setOpen={setOpenUploadDrawer}
        title={t("profilesettings_addProfilePgTitle")}
        primaryBtnText={t("profilesettings_confirmBtn")}
        secondaryBtnText={t("profilesettings_cancelBtn")}
        btnOnClick={() => setOpenPreviewDrawer(true)}
        btnSecondaryOnClick={handleCloseDrawer}
        btnDisabled={isDisabledUpload}
      >
        <div className={`${styles.uploadCont}`}>
          <div className="bs-upload">
            <div className="doc-card">
              {uploadedFile.id === "" ? (
                <div className="upload-section">
                  {/* <div className="upload-item capture-pic">
                    <button></button>
                    <span className="text">
                      {t("profilesettings_clickPicture")}
                    </span>
                  </div> */}
                  <div className="upload-item upload-file">
                    <span className="text">
                      {t("profilesettings_uploadTitle")}
                    </span>
                    <input
                      type="file"
                      name=""
                      id="upload-doc"
                      onChange={handleUpload}
                    />
                  </div>
                </div>
              ) : (
                <div className="uploaded-state">
                  <div className="cm-document-wrap">
                    <i
                      className={`document-icon ${
                        uploadedFile.type.indexOf("image") !== -1
                          ? "image"
                          : "document"
                      }`}
                    ></i>
                    <div className="info-wrap">
                      <span className="doc-title">{uploadedFile.name}</span>
                      <span className="foot-note">{uploadedFile.size} KB</span>
                    </div>
                    <div className="action-wrap">
                      <button
                        type="button"
                        className="show-media"
                        onClick={() => setOpenPreviewDrawer(true)}
                      >
                        <i></i>
                      </button>
                      <button
                        type="button"
                        className="trash-btn"
                        onClick={handleCancelPreview}
                      >
                        <i></i>
                      </button>
                    </div>
                  </div>
                </div>
              )}

              <div className="upload-note">
                <strong className="note-title">
                  <i></i>
                  {t("profilesettings_noteTitle")}
                </strong>
                <ul className="note-list">
                  <li className="note-item">
                    {t("profilesettings_maxFileSize")}
                  </li>
                </ul>
              </div>
            </div>
            {errorMessage && <p className="error-text">{errorMessage}</p>}
          </div>
        </div>
      </BottomSheet>
      <BottomSheet
        open={openPreviewDrawer}
        primaryBtnText={t("profilesettings_confirmBtn")}
        btnDisabled={false}
        size="large"
        title={uploadedFile.name}
        setOpen={setOpenPreviewDrawer}
        btnOnClick={handleConfirmUpload}
      >
        <div className="upload-preview">
          {uploadedFile.type.indexOf("image") !== -1 ? (
            <img src={tempProfileImage} alt="" />
          ) : (
            <iframe src={tempProfileImage}></iframe>
          )}
        </div>
      </BottomSheet>
    </>
  );
}

export default ProfileCard;
